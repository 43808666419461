import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'

const Login = () => // 登录页面
  import('../views/login.vue');
const Home = () => // 侧边栏
  import('../views/Home.vue');
const canvas = () =>
  import('../views/canvas.vue')
const submitReport = () => // 提交报告
  import('../views/Submit/report/report');
const Hidden = () => // 隐患排查
  import('../views/HiddenTrouble/Hidden/Hidden');
const RiskManagement = () => // 风险管理
  import('../views/RiskControl/Risk/Risk');
const RiskControl = () => // 风险管理
  import('../views/RiskControl/RiskControl/RiskControl');
const Screening = () => // 风险管理
  import('../views/RiskControl/Screening/Screening');
const Screeningsb = () => // 风险管理
  import('../views/RiskControl/Screening/Screeningsb.vue');
const projectinfo = () => // 项目管理
  import('../views/Project/projectInfo/projectinfo');
  const rechargeRecords = () => // 充值记录
  import('../views/Project/rechargeRecords/rechargeRecords');
const userInfo = () => // 人员管理
  import('../views/Project/user/userInfo');
const a1 = () => // 人员管理
  import('../views/Project/user/a1');
const a2 = () => // 人员管理
  import('../views/Project/user/a2');
const enterprise = () => // 企业管理
  import('../views/Project/enterprise/enterprise');
const visualization = () => // 可视化
  import('../views/visualization');
const Upload = () => // 文件上传
  import('../views/Submit/upload/upload');
const SignificantRisk = () => // 重大风险统计
  import('../views/RiskControl/SignificantRisk/SignificantRisk');
const RecordsFebruary = () => // 隐患排查记录
  import('../views/HiddenTrouble/RecordsFebruary/RecordsFebruary');
const HiddenGovernance = () => // 隐患治理台账
  import('../views/HiddenTrouble/HiddenGovernance/HiddenGovernance');
const MajorHiddenGovernance = () => // 重大隐患治理台账
  import('../views/HiddenTrouble/MajorHiddenGovernance/MajorHiddenGovernance');
const SystemAssessment = () => // 系统评估记录
  import('../views/Assessment/SystemAssessment/SystemAssessment');
const SummaryAssessment = () => // 自评记录汇总
  import('../views/Assessment/SummaryAssessment/SummaryAssessment');

const evaluationRegulatory = () => // 月自评表监管
  import('../views/ProductionStandard/evaluationRegulatory/evaluationRegulatory');
const pictureRegulatory = () => // 施工阶段照片监管
  import('../views/ProductionStandard/pictureRegulatory/pictureRegulatory');
const planRegulatory = () => // 申报方案监管
  import('../views/ProductionStandard/planRegulatory/planRegulatory');
const projectRegulatory = () => //  项目概况监管
  import('../views/ProductionStandard/projectRegulatory/projectRegulatory');
// const equiment = () => //  项目概况监管
//     import ('../views/Project/equiment/equiment');
const statistics = () => //  归档文件统计
  import('../views/statistics/statistics');
const additem = () => // 添加项目
  import('../views/addItem/additem.vue');
Vue.use(VueRouter)

const routes = [{
  path: '/',
  redirect: '/Login'
}, {
  path: '/Login',
  name: 'Login',
  component: Login
}, {
  path: '/canvas',
  name: 'canvas',
  component: canvas
}, {
  path: '/visualization',
  name: 'visualization',
  component: visualization
}, {
  path: '/Home',
  name: 'Home',
  component: Home,
  // children: [{
  //         path: '/report',
  //         name: "report",
  //         redirect: "/report/submitReport",
  //         children: [{
  //                 path: '/submitReport',
  //                 name: 'submitReport',
  //                 component: submitReport,
  //                 meta: {
  //                     title: "提交报告",
  //                     requiresAuth: true
  //                 }
  //             },
  //             {
  //                 path: '/Upload',
  //                 name: 'Upload',
  //                 component: Upload,
  //                 meta: {
  //                     title: "文件管理",
  //                     requiresAuth: true
  //                 }
  //             },
  //         ]
  //     },
  //     {
  //         path: '/trouble',
  //         children: [{
  //                 path: '/Hidden',
  //                 name: 'Hidden',
  //                 component: Hidden,
  //                 meta: {
  //                     title: "隐患排查清单",
  //                     requiresAuth: true
  //                 }
  //             },
  //             {
  //                 path: '/RecordsFebruary',
  //                 name: 'RecordsFebruary',
  //                 component: RecordsFebruary,
  //                 meta: {
  //                     title: "隐患排查记录",
  //                     requiresAuth: true
  //                 }
  //             },
  //             {
  //                 path: '/HiddenGovernance',
  //                 name: 'HiddenGovernance',
  //                 component: HiddenGovernance,
  //                 meta: {
  //                     title: "隐患治理台账",
  //                     requiresAuth: true
  //                 }
  //             },
  //             {
  //                 path: '/MajorHiddenGovernance',
  //                 name: 'MajorHiddenGovernance',
  //                 component: MajorHiddenGovernance,
  //                 meta: {
  //                     title: "重大隐患治理台账",
  //                     requiresAuth: true
  //                 }
  //             },
  //         ]
  //     },
  //     {
  //         path: "/risk",
  //         children: [{
  //                 path: '/RiskManagement',
  //                 name: 'RiskManagement',
  //                 component: RiskManagement,
  //                 meta: {
  //                     title: "风险管理",
  //                     requiresAuth: true
  //                 }
  //             },
  //             {
  //                 path: '/RiskControl',
  //                 name: 'RiskControl',
  //                 component: RiskControl,

  //             },
  //             {
  //                 path: '/Screening',
  //                 name: 'Screening',
  //                 component: Screening,
  //                 meta: {
  //                     title: "风险管控统计",
  //                     requiresAuth: true
  //                 }
  //             },
  //             {
  //                 path: '/SignificantRisk',
  //                 name: 'SignificantRisk',
  //                 component: SignificantRisk,
  //                 meta: {
  //                     title: "重大风险统计",
  //                     requiresAuth: true
  //                 }
  //             },
  //         ]
  //     },
  //     {
  //         path: "/assessment",
  //         children: [{
  //                 path: '/SummaryAssessment',
  //                 name: 'SummaryAssessment',
  //                 component: SummaryAssessment,
  //                 meta: {
  //                     title: "自评记录汇总",
  //                     requiresAuth: true
  //                 }
  //             },
  //             {
  //                 path: '/SystemAssessment',
  //                 name: 'SystemAssessment',
  //                 component: SystemAssessment,
  //                 meta: {
  //                     title: "系统评估记录",
  //                     requiresAuth: true
  //                 }
  //             },
  //         ]
  //     },
  //     {
  //         path: "/production",
  //         children: [{
  //                 path: '/applyFor',
  //                 name: 'applyFor',
  //                 component: applyFor,
  //                 meta: {
  //                     title: "中止/复工申请",
  //                     requiresAuth: true
  //                 }
  //             },
  //             {
  //                 path: '/evaluationRegulatory',
  //                 name: 'evaluationRegulatory',
  //                 component: evaluationRegulatory,
  //                 meta: {
  //                     title: "月自评表监管",
  //                     requiresAuth: true
  //                 }
  //             },
  //             {
  //                 path: '/pictureRegulatory',
  //                 name: 'pictureRegulatory',
  //                 component: pictureRegulatory,
  //                 meta: {
  //                     title: "施工阶段照片监管",
  //                     requiresAuth: true
  //                 }
  //             },
  //             {
  //                 path: '/planRegulatory',
  //                 name: 'planRegulatory',
  //                 component: planRegulatory,
  //                 meta: {
  //                     title: "申报方案监管",
  //                     requiresAuth: true
  //                 }
  //             },
  //             {
  //                 path: '/projectRegulatory',
  //                 name: 'projectRegulatory',
  //                 component: projectRegulatory,
  //                 meta: {
  //                     title: "项目概况监管",
  //                     requiresAuth: true
  //                 }
  //             },
  //         ]
  //     },
  //     {
  //         path: "/stytem",
  //         children: [{
  //                 path: '/projectinfo',
  //                 name: 'projectinfo',
  //                 component: projectinfo,
  //                 meta: {
  //                     title: "项目管理",
  //                     requiresAuth: true
  //                 }
  //             },
  //             {
  //                 path: '/userInfo',
  //                 name: 'userInfo',
  //                 component: userInfo,
  //                 meta: {
  //                     title: "人员管理",
  //                     requiresAuth: true
  //                 }
  //             },
  //             {
  //                 path: '/enterprise',
  //                 name: 'enterprise',
  //                 component: enterprise,
  //                 meta: {
  //                     title: "企业管理",
  //                     requiresAuth: true
  //                 }
  //             },
  //             {
  //                 path: '/equiment',
  //                 name: 'equiment',
  //                 component: equiment,
  //                 meta: {
  //                     title: "升级设备",
  //                     requiresAuth: true
  //                 }
  //             },
  //         ]
  //     }
  // ],


  children: [{
    path: '/submitReport',
    name: 'submitReport',
    component: submitReport,
    meta: {
      title: "提交报告",
      requiresAuth: true
    }
  }, {
    path: '/Hidden',
    name: 'Hidden',
    component: Hidden,
    meta: {
      title: "隐患排查清单",
      requiresAuth: true
    }
  }, {
    path: '/RiskManagement',
    name: 'RiskManagement',
    component: RiskManagement,
    meta: {
      title: "风险管理",
      requiresAuth: true
    }
  }, {
    path: '/projectinfo',
    name: 'projectinfo',
    component: projectinfo,
    meta: {
      title: "项目管理",
      requiresAuth: true
    }
  },
  {
    path: '/rechargeRecords',
    name: 'rechargeRecords',
    component: rechargeRecords,
    meta: {
      title: "充值记录",
      requiresAuth: true
    }
  },
  {
    path: '/userInfo',
    name: 'userInfo',
    component: userInfo,
    meta: {
      title: "人员管理",
      requiresAuth: true
    }
  },
  {
    path: '/a1',
    name: 'a1',
    component: a1,
    meta: {
      title: "111",
      requiresAuth: true
    }
  },
  {
    path: '/a2',
    name: 'a2',
    component: a2,
    meta: {
      title: "222",
      requiresAuth: true
    }
  },
  {
    path: '/enterprise',
    name: 'enterprise',
    component: enterprise,
    meta: {
      title: "企业管理",
      requiresAuth: true
    }
  },
  {
    path: '/RiskControl',
    name: 'RiskControl',
    component: RiskControl,

  },
  {
    path: '/Screening',
    name: 'Screening',
    component: Screening,
    meta: {
      title: "风险管控统计",
      requiresAuth: true
    }
  },
  {
    path: '/Screeningsb',
    name: 'Screeningsb',
    component: Screeningsb,
    meta: {
      title: "设备设施风险管控统计",
      requiresAuth: true
    }
  },
  {
    path: '/Upload',
    name: 'Upload',
    component: Upload,
    meta: {
      title: "文件管理",
      requiresAuth: true
    }
  },
  {
    path: '/SignificantRisk',
    name: 'SignificantRisk',
    component: SignificantRisk,
    meta: {
      title: "重大风险统计",
      requiresAuth: true
    }
  },
  {
    path: '/RecordsFebruary',
    name: 'RecordsFebruary',
    component: RecordsFebruary,
    meta: {
      title: "隐患排查记录",
      requiresAuth: true
    }
  },
  {
    path: '/HiddenGovernance',
    name: 'HiddenGovernance',
    component: HiddenGovernance,
    meta: {
      title: "隐患治理台账",
      requiresAuth: true
    }
  },
  {
    path: '/MajorHiddenGovernance',
    name: 'MajorHiddenGovernance',
    component: MajorHiddenGovernance,
    meta: {
      title: "重大隐患治理台账",
      requiresAuth: true
    }
  },
  {
    path: '/SummaryAssessment',
    name: 'SummaryAssessment',
    component: SummaryAssessment,
    meta: {
      title: "自评记录汇总",
      requiresAuth: true
    }
  },
  {
    path: '/SystemAssessment',
    name: 'SystemAssessment',
    component: SystemAssessment,
    meta: {
      title: "系统评估记录",
      requiresAuth: true
    }
  },

  {
    path: '/evaluationRegulatory',
    name: 'evaluationRegulatory',
    component: evaluationRegulatory,
    meta: {
      title: "月自评表监管",
      requiresAuth: true
    }
  },
  {
    path: '/pictureRegulatory',
    name: 'pictureRegulatory',
    component: pictureRegulatory,
    meta: {
      title: "施工阶段照片监管",
      requiresAuth: true
    }
  },
  {
    path: '/planRegulatory',
    name: 'planRegulatory',
    component: planRegulatory,
    meta: {
      title: "申报方案监管",
      requiresAuth: true
    }
  },
  {
    path: '/projectRegulatory',
    name: 'projectRegulatory',
    component: projectRegulatory,
    meta: {
      title: "项目概况监管",
      requiresAuth: true
    }
  },
  // {
  //     path: '/equiment',
  //     name: 'equiment',
  //     component: equiment,
  //     meta: {
  //         title: "设备升级",
  //         requiresAuth: true
  //     }
  // },
  {
    path: '/statistics',
    name: 'statistics',
    component: statistics,
    meta: {
      title: "归档文件统计",
      requiresAuth: true
    }
  },
  {
    path: '/additem',
    name: 'additem',
    component: additem,
    meta: {
      title: "添加项目",
      requiresAuth: true
    }
  },
  ]
},

]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  // 在这里可以获取用户跳转的路径
  console.log(to.path); // 用户跳转的路径
localStorage.setItem('to',to.path)
  next(); // 确保路由继续向下跳转
});
export default router
