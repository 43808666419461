import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/fonts/iconfont.css';
import axios from 'axios';
Vue.prototype.$axios = axios;
import * as echarts from 'echarts';
import  './assets/icon/iconfont.js'
Vue.prototype.$echarts = echarts
import 'echarts/extension/bmap/bmap';

import moment from 'moment' //导入文件      
Vue.prototype.$moment = moment; //赋值使用

// import BaiduMap from 'vue-baidu-map';
// Vue.use(BaiduMap, {
//     ak: 'O9CGqgSNqDRQK5oFoG4UgBonBH87wKxu',
// });

import Print from 'vue-print-nb'
Vue.use(Print); //注册
Vue.use(ElementUI)
Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')